<template>
  <v-container>
    <div class="contact animate__animated animate__fadeInUp">
      <div class="text-lg-h2 text-h4 contact__title">
        Contact
      </div>
      <v-row class="contact__container">
        <v-col class="contact__details col-md-5">
          <div class="text-lg-h4 text-md-h6 font-weight-thin">Address: <a
              target="_blank"
              href="https://www.google.co.za/maps/place/Scalco+Attorneys+Inc./@-26.1369276,27.9614465,20z/data=!4m5!3m4!1s0x1e950ad1b611ce23:0x21eeca7a50f2dafa!8m2!3d-26.13697!4d27.96157?hl=en">204 Weltevreden Rd, Northcliff</a></div>
          <div class="text-lg-h4 text-md-h6 font-weight-thin">Phone: <a href="tel:011-476-6632">011-476-6632</a></div>
        </v-col>
        <v-col class="contact__map col-md-7">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d447.7293548920079!2d27.96144650661248!3d-26.136927601936385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950ad1b611ce23%3A0x21eeca7a50f2dafa!2sScalco%20Attorneys%20Inc.!5e0!3m2!1sen!2sza!4v1602880130382!5m2!1sen!2sza"
            width="800" height="500"
            frameborder="0" style="border:0;"
            class="contact__iframe"
            allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Contact",
  computed: {
    mapHolderSize() {
      let width = 800;
      let height = 500;
      if (window.innerWidth < width) {
        width = window.innerWidth;
      }
      if (window.innerHeight < height) {
        height = window.innerHeight;
      }
      return {
        width,
        height
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scalco.scss';
a {
  color: darkblue;
}
.contact {
  &__container {

  }
  &__title {

  }
  &__details {
    border-top: #e1be84 solid;
  }
  &__map {
    display: flex;
    height: 100%;
    width: 100%;
  }
  &__contact {
    display: flex;
  }
  &__iframe {
    display: flex;
  }
  &__lrg-card-background {
    background: linear-gradient(180deg, white 60%, $scalco-blue 60%);
  }
}
</style>