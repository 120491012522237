<template>
  <v-container class="attorney animate__animated animate__fadeInUp">
    <v-row class="attorney__row">
      <v-col v-for="attorney in attorneys" :key="attorney.attorney">
        <v-row class="attorney__box" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'attorney__lrg-card-background'">
          <v-col class="col-md-4 attorney__image">
            <v-img class="mx-auto d-block" :src='`./${attorney.picture}-500.jpeg`'></v-img>
          </v-col>
          <v-col class="col-md-8 attorney__bio">
            <div class="attorney__name text-lg-h2 text-h4">{{attorney.attorney}}</div>
            <p class="attorney__paragraphs text-lg-body-1 text-body-2" v-for="entry in attorney.paragraphs" :key="entry">{{entry}}</p>
            <div class="text-lg-body-1 text-body-2">Email: <a :href="`mailto:${attorney.email}`"><span>{{attorney.email}}</span></a></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Attorneys",
  data() {
    return {
      attorneys: [
        { attorney: 'Hannah Scalco', picture: 'hannah', paragraphs: ['Hannah obtained her BCOM and LLB cum laude from the University of the Witwatersrand and was admitted as an attorney in 2014. Thereafter, she clerked for the Honourable Judge Victor at the High Court in 2014, and then joined Clorinda’s practice in 2015. Hannah has also obtained her LLM in Human Rights Advocacy and Litigation through the University of the Witwatersand.', 'Hannah has a special interest in Sectional Title Law and Family Law.'], email: 'Hannah@scalcoattorneys.co.za'},
        { attorney: 'Clorinda Scalco', picture: 'clorinda', paragraphs: ['Clorinda is a veteran Family Law attorney with 38 years of experience. She obtained a BA and LLB through the University of the Witwatersrand, was admitted as an Attorney in 1982 and obtained her right of appearance in the High Court in 1996.', 'Clorinda opened her practice in 1985, has made a name for herself in the areas of Family Law, Estate Administration, Civil Litigation and Motor Industry disputes.'], email: 'Clorinda@scalcoattorneys.co.za'},
        { attorney: 'Kyle Abrahams', picture: 'kyle', paragraphs: ['Kyle joined Scalco Attorneys Inc as a Candidate Attorney in April 2019. He obtained a BCom Law and LLB through the University of Witwatersrand.', 'Kyle was admitted as an attorney of the High Court of South Africa in 2020 and currently is a junior associate and support lawyer.', 'Kyle has a special interest in Family Law, Civil Litigation and Contractual Disputes.'], email: 'Kyle@scalcoattorneys.co.za'},
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scalco.scss';
a {
  color: darkblue;
}
.attorney {
  &__row {
    display: flex;
    flex-direction: column;
  }
  &__box {
    display: flex;
    flex-direction: row;
  }
  &__lrg-card-background {
    background: linear-gradient(180deg, white 60%, $scalco-blue 60%);
  }
  &__image {
    display: flex;
  }
  &__bio {
    display: flex;
    flex-direction: column;
  }
  &__name {
    border-bottom: $scalco-gold solid 2px;
    margin-bottom: 1rem;
  }
}
</style>